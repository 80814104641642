import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {Header} from "../../header/Header";
import {getRequest} from "../../../helpers/getRequest";
import {zynity_api} from "../../../config/external-routes";
import {Loading} from "../../ui/loading/Loading";
import {useSelector} from "react-redux";
import {constants} from "../../../config/constants";
import ReactQuill from "react-quill";
import axios from "axios";
import Swal from "sweetalert2";


export const ContentEditor = () => {
  const {page} = useParams()
  const authenticated_user = useSelector(state => state.auth)
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})

  useEffect(() => {
    getRequest(`${zynity_api.admin_editor_content}?content_name=${page}`, authenticated_user.access_token).then((response) => {
      setContent(response.content)
      setLoading(false)
    })
  }, [])

  const submitContentHandle = () => {
    let data = {
      "content_name": page,
      "html_content": content,
    }
    axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
    axios.post(`${zynity_api.admin_editor_content}`, data)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Content updated',
        })
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'We have problems updating the Content!',
        })
      })
  }

  // Get current month and year
  const currentDate = new Date();
  const monthYear = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mt-4 mb-4 d-flex justify-content-between">
          <div className="col-12 col-md-7">
            <h4 className="text-muted">
              Admin Content Editor
              	<span style={{fontSize: '15px'}}>
				  &nbsp; &nbsp; <a href="/admin/z-admin">Admin Home</a>
				</span>
            </h4>
            <div className="card">
              <div className="card-body">
                <div className="card-title row">
                  <div className="col-6">
                    <h5 className="card-title">Editor</h5>
                  </div>
                </div>
                {
                  loading ?
                    (<Loading />)
                    :
                    <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                      modules={constants.quillDefaultModules}
                      formats={constants.quillDefaultFormats}
                    />
                }
              </div>
              <div className="card-footer bg-transparent d-flex flex-row-reverse">
                <button type="submit"
                    onClick={submitContentHandle}
                    className="btn btn-primary">Update
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5">
            <h4 className="text-muted">
              Content Preview
            </h4>
            <div className="usernews">
              <h5>Member News</h5>
              <p style={{ marginTop: '-10px', marginBottom: '20px', fontStyle: 'italic', fontWeight: 'bold' }}>{monthYear}</p>
              <div dangerouslySetInnerHTML={{__html: content}}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
