import React, {useEffect, useRef, useState} from 'react'
import Nav from "react-bootstrap/Nav";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "../../../../../ui/loading/Loading";
import {Link} from "react-router-dom";
import {setRoute} from "../../../../../../store/routes";
import {
    collabSetPanelViewRemember,
    collabSetRemember, ICollabRemember,
    setDatumIdOpen
} from "../../../../../../store/user_organizations/teams/tools/connect_and_align";
import {cutText} from "../../../../../../helpers/tools";

export const CnATeamCard = ({data, setSelectedMember}:any) => {
    const dispatch = useDispatch();
    const team_obj = useSelector((state: any) => state.organization_team);
    const tool_cna_remember = useSelector((state: any) => state.tool_cna_remember);
    const [displayDeliverables, setDisplayDeliverables] = useState(true)
    const [displayTomis, setDisplayTomis] = useState(false)
    const [displayActivities, setDisplayActivities] = useState(false)
    const [displayMessages, setDisplayMessages] = useState(false)

    let team_services = team_obj.data.services[0];

    let styleLink = {backgroundColor: '#fff', color: 'black', padding: '5px 3px'};
    let styleLinkActive = {backgroundColor: '#FFFADE', color: 'black', borderColor: '#d7d191', padding: '5px 3px'};

    useEffect(() => {

        let remember_data = tool_cna_remember?.remember
        if(remember_data != null && remember_data.length > 0) {
            let {data: org_team_data} = team_obj

            let organizationData = remember_data.find((org: { organization_id: number; team_id:number; view:'team'|'member' }) => (
                org.organization_id === org_team_data.idOrganization && org.team_id === org_team_data.id && org.view === 'team'
            ));

            if(organizationData) {
                let user_data = organizationData.users.find((user: { id: any; }) => user.id == data.user_id);
                if(user_data != null && user_data?.tabs != null && user_data.tabs.length > 0) {

                    let selected_tab = user_data.tabs.find((tab: { open: boolean; }) => tab.open == true)
                    if(selected_tab != null && selected_tab?.name != null && selected_tab?.name.trim() != '') {
                        resetSelectedTab();
                        if(selected_tab.name == 'deliverables') {
                            setDisplayDeliverables(true);
                        } else if(selected_tab.name == 'tomis') {
                            setDisplayTomis(true);
                        } else if(selected_tab.name == 'activities') {
                            setDisplayActivities(true);
                        } else if(selected_tab.name == 'messages') {
                            setDisplayMessages(true);
                        }
                    }
                }
            }
        }

    }, [])

    const resetSelectedTab = () => {
        setDisplayDeliverables(false);
        setDisplayTomis(false);
        setDisplayActivities(false);
        setDisplayMessages(false);
    }

    const selectTabHandle = (tab: 'deliverables'|'tomis'|'activities'|'messages') => {
        resetSelectedTab();

        if(tab == 'deliverables') {
            setDisplayDeliverables(true);
        } else if(tab == 'tomis') {
            setDisplayTomis(true);
        } else if(tab == 'activities') {
            setDisplayActivities(true);
        } else if(tab == 'messages') {
            setDisplayMessages(true);
        }

        let {data: org_team_data} = team_obj

        let rememberData:ICollabRemember = {
            organization_id: org_team_data.idOrganization,
            team_id: org_team_data.id,
            view: 'team',
            user_id: data.user_id,
            tab_name: tab,
            selected_item_id: null,
        }

        dispatch(collabSetRemember(rememberData))
    }

    if(team_obj.isLoading === true) {
        return <Loading />;
    }

    const getAuthorKnowAs = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return 'Not Found';
        }

        let know_as = author.KnownAs;
        if(know_as == '' || know_as == undefined) {
            know_as = author.EmailAddress.includes('@') ? author.EmailAddress.split('@')[0] : author.EmailAddress;
        }

        return know_as;
    }

    const getAuthorInitials = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return '--';
        }

        if(author.FirstName == '' || author.FirstName == undefined) {
            return author.EmailAddress.substring(0, 2).toUpperCase()
        }

        return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
    }

    const navigateToTool = (datum_id:number, tab:string) => {
        const route = `/organizations/${team_obj.data.idOrganization}/teams/${team_obj.data.id}/CnA/${datum_id}`
        dispatch(setRoute(route))
        dispatch( setDatumIdOpen(datum_id) )

        let rememberData:any = {
            organization_id: team_obj.data.idOrganization,
            team_id: team_obj.data.id,
            view: 'member',
            user_id: data.user_id,
            tab_name: tab.toLowerCase(),
            selected_item_id: datum_id,
        }

        dispatch(collabSetRemember(rememberData))
    }

    return (
        <div className="card bg-note">
            <div className="card-header" style={{backgroundColor: '#F0F0F0'}}>
                <div className="d-flex align-items-center">
                    <div className="rounded-circle" style={{backgroundColor: 'teal', paddingTop: '5px', paddingBottom: '3px', height: '28px', width: '28px', color: 'white', display: 'inline', textAlign: 'center', cursor: 'pointer', fontSize: '12px', fontFamily: 'sans-serif'}}
                         onClick={() => setSelectedMember(data.user_id)}
                    >
                        {getAuthorInitials(data.user_id)}
                    </div>
                    <span className='mx-1' style={{cursor: 'pointer'}} onClick={() => setSelectedMember(data.user_id)}>{getAuthorKnowAs(data.user_id)}</span>
                </div>
            </div>
            <div className="card-body p-0" style={{height: '230px', overflowY: 'auto', fontSize: '13px'}}>
                <Nav fill variant="tabs">
                    <Nav.Item className={'border border-secondary-subtle'} onClick={() => {selectTabHandle('deliverables')}}>
                        <Nav.Link style={displayDeliverables ? styleLinkActive : styleLink}>
                            Deliverables <sup style={{color: "red", fontWeight: 'bold', fontSize: '12px'}}>{data.deliverables.total_notifications > 0 && data.deliverables.total_notifications}</sup>
                        </Nav.Link>
                    </Nav.Item>
                    {
                        (team_services.SKU == 'Z01B' || team_services.SKU == 'Z01C') &&
                        <>
                            <Nav.Item className={'border border-secondary-subtle'} onClick={() => {selectTabHandle('tomis')}}>
                                <Nav.Link  style={displayTomis ? styleLinkActive : styleLink}>
                                    ToMIs™ <sup style={{color: "red", fontWeight: 'bold', fontSize: '12px'}}>{data.tomis.total_notifications > 0 && data.tomis.total_notifications}</sup>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={'border border-secondary-subtle'} onClick={() => {selectTabHandle('activities')}}>
                                <Nav.Link style={displayActivities ? styleLinkActive : styleLink}>
                                    Activities <sup style={{color: "red", fontWeight: 'bold', fontSize: '12px'}}>{data.activities.total_notifications > 0 && data.activities.total_notifications}</sup>
                                </Nav.Link>
                            </Nav.Item>
                        </>
                    }

                    <Nav.Item className={'border border-secondary-subtle'} onClick={() => {selectTabHandle('messages')}}>
                        <Nav.Link style={displayMessages ? styleLinkActive : styleLink}>
                            Messages <sup style={{color: "red", fontWeight: 'bold', fontSize: '12px'}}>{data.messages.total_notifications > 0 && data.messages.total_notifications}</sup>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {
                    displayDeliverables &&
                    data.deliverables.data.map( (datum:any) => (
                        <div onClick={() => navigateToTool(datum.id, 'deliverables')}
                             style={{borderBottom: 'solid 1px #939393FF', padding: '7px', paddingLeft: '10px', cursor: 'pointer', fontSize: '16px'}} className="d-flex justify-content-between align-items-center">
                            <div>{datum.listOrder} - {cutText(datum.title)}</div>
                            <div className='d-flex align-items-center'>
                                {
                                    datum.total_notifications > 0 &&
                                    <span className="badge" style={{backgroundColor: '#B22222', margin: '1px 3px'}}>{datum.total_notifications}</span>
                                }
                                {
                                    datum.datumNotificationReminderId > 0 &&
                                    <span className="badge" style={{backgroundColor: 'teal', margin: '1px 3px'}}>
                                        <i className="fa fa-bell"></i>
                                    </span>
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    displayTomis &&
                    data.tomis.data.map( (datum:any) => (
                        datum.isPrivate != 'Private' &&
                        <div
                            onClick={() => navigateToTool(datum.id, 'tomis')}
                            style={{borderBottom: 'solid 1px #939393FF', padding: '7px', paddingLeft: '10px', cursor: 'pointer', fontSize: '16px'}} className="d-flex justify-content-between align-items-center">
                            <div>{datum.listOrder} - {cutText(datum.title)}</div>
                            <div className='d-flex align-items-center'>
                                {
                                    datum.total_notifications > 0 &&
                                    <span className="badge" style={{backgroundColor: '#B22222', margin: '1px 3px'}}>{datum.total_notifications}</span>
                                }
                                {
                                    datum.datumNotificationReminderId > 0 &&
                                    <span className="badge" style={{backgroundColor: 'teal', margin: '1px 3px'}}>
                                        <i className="fa fa-bell"></i>
                                    </span>
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    displayActivities &&
                    data.activities.data.map( (datum:any) => (
                        <div onClick={() => navigateToTool(datum.id, 'activities')}
                            style={{borderBottom: 'solid 1px #939393FF', padding: '7px', paddingLeft: '10px', cursor: 'pointer', fontSize: '16px'}} className="d-flex justify-content-between align-items-center">
                            <div>{datum.listOrder} - {cutText(datum.title)}</div>
                            <div className='d-flex align-items-center'>
                                {
                                    datum.total_notifications > 0 &&
                                    <span className="badge" style={{backgroundColor: '#B22222', margin: '1px 3px'}}>{datum.total_notifications}</span>
                                }
                                {
                                    datum.datumNotificationReminderId > 0 &&
                                    <span className="badge" style={{backgroundColor: 'teal', margin: '1px 3px'}}>
                                        <i className="fa fa-bell"></i>
                                    </span>
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    displayMessages &&
                    data.messages.data.map( (datum:any) => (
                        <div onClick={() => navigateToTool(datum.id, 'messages')}
                            style={{borderBottom: 'solid 1px #939393FF', padding: '7px', paddingLeft: '10px', cursor: 'pointer', fontSize: '16px'}} className="d-flex justify-content-between align-items-center">
                            <div>{datum.listOrder} - {cutText(datum.title)}</div>
                            <div className='d-flex align-items-center'>
                                {
                                    datum.total_notifications > 0 &&
                                    <span className="badge" style={{backgroundColor: '#B22222', margin: '1px 3px'}}>{datum.total_notifications}</span>
                                }
                                {
                                    datum.datumNotificationReminderId > 0 &&
                                    <span className="badge" style={{backgroundColor: 'teal', margin: '1px 3px'}}>
                                        <i className="fa fa-bell"></i>
                                    </span>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
