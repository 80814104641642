import React from 'react'
import {useSelector} from "react-redux";
import {Loading} from "../../../../../ui/loading/Loading";
import {CnATeamCard} from "./CnATeamCard";

export const TeamPanel = ({setSelectedMember}:any) => {
  const cna = useSelector((state: any) => state.tool_cna)
  let cna_data = cna.data;

  if (cna.isLoading) {
    return <Loading/>
  }

  return (
    <div className="row">
      {
        cna_data.map((datum: any) => (
          <>
            <div className="col-12 col-lg-6 col-xxl-4 col-xl-6 mt-3">
              <CnATeamCard data={datum} setSelectedMember={setSelectedMember}/>
            </div>
          </>
        ))
      }
    </div>
  )
}
